import React, { useState, Suspense, lazy, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import AOS from 'aos';
import StaticWithSearch from './components/Content/Layouts/StaticWithSearch.js';
import ModalRoot from './components/Shared/Modals/ModalRoot';
import GDPRPanel from './components/Shared/GDPRPanel';
import NeedsModal from './components/Shared/Modals/NeedsModal';
import S12FreeTrialModal from './components/Shared/Modals/S12FreeTrialModal';
import { isMobile, getUA, isIE } from 'react-device-detect';
import { fromIP, fromBrowser } from './ajax/geo';
import * as Search from './store/search';
import * as Cart from './store/cart';
import { useGtmVariables } from './store/gtm';
import { useEffectOnce } from './utils/hooks';
import { useScrollToTopEffect } from './utils/scroll';
import { useSite, actionCreators } from './store/site';
import { useCustomerService } from './store/customerService.reducer.js';

import { ENTERPRISE_URL, BRANDS } from './constants';

import Header from './components/Header';
import Footer from './components/Footer';
import BackToTopButton from './components/Shared/BackToTopButton';
import LoadingSpinner from './components/Shared/LoadingSpinner.js';

const HomePage = lazy(() => import('./components/HomePage')); 
const SearchPage = lazy(() => import('./components/SearchPage'));

const SeminarPage = lazy(() => import('./components/Seminar/SeminarPage')); 
const SeminarTopicPage = lazy(() => import('./components/Seminar/SeminarTopicPage')); 

const WebinarPage = lazy(() => import('./components/Webinar/WebinarPage')); 
const WebinarTopicPage = lazy(() => import('./components/Webinar/WebinarTopicPage'));
const WebinarLaunchPage = lazy(() => import('./components/Webinar/WebinarLaunchPage')); 

const VirtualSeminarPage = lazy(() => import('./components/VirtualSeminar/VirtualSeminarPage')); 
const VirtualSeminarTopicPage = lazy(() => import('./components/VirtualSeminar/VirtualSeminarTopicPage'));

const ProductPage = lazy(() => import('./components/Product/ProductPage'));
const ProductItemPage = lazy(() => import('./components/Product/ProductItemPage')); 

const OSHALandingPage = lazy(() => import('./components/TopicLanding/OSHALandingPage'));
const ConflictLandingPage = lazy(() => import('./components/TopicLanding/ConflictLandingPage'));
const BusWritingLandingPage = lazy(() => import('./components/TopicLanding/BusWritingLandingPage'));
const XMNINTLandingPage = lazy(() => import('./components/TopicLanding/XMNINTLandingPage'));
const CustomerServiceLinks = lazy(() => import('./components/CustServLinks.js'));
const Dominos = lazy(() => import('./components/TopicLanding/Dominos'));

const AssertivenessQuiz = lazy(() => import('./components/Quiz/AssertivenessQuiz'));
const HiringQuiz = lazy(() => import('./components/Quiz/HiringQuiz'));

const PassRenewalPage = lazy(() => import('./components/Star12/PassRenewalPage')); 
const PassConversionPage = lazy(() => import('./components/Star12/PassConversionPage'));
const UnlimitedRenewalPage = lazy(() => import('./components/Star12/UnlimitedRenewalPage'));
const PassItemPage = lazy(() => import('./components/Star12/PassItemPage'));

const OndemandPage = lazy(() => import('./components/Ondemand/OndemandPage'));
const OndemandCoursePage = lazy(() => import('./components/Ondemand/OndemandCoursePage'));

const BlogPage = lazy(() => import('./components/Blog/BlogPage'));
const BlogPostPage = lazy(() => import('./components/Blog/BlogPostPage'));

const NewsPage = lazy(() => import('./components/News/NewsPage'));
const NewsItemPage = lazy(() => import('./components/News/NewsItemPage')); 

const ResourcePage = lazy(() => import('./components/Resource/ResourcePage'));
const ResourceItemPage = lazy(() => import('./components/Resource/ResourceItemPage'));
const ResourceLandingPage = lazy(() => import('./components/Resource/ResourceLandingPage'));

const PodcastPage = lazy(() => import('./components/Podcast/PodcastPage'));
const PodcastEpisodePage = lazy(() => import('./components/Podcast/PodcastEpisodePage'));
const PodcastLandingPage = lazy(() => import('./components/Podcast/PodcastLandingPage'));

const EmailPage = lazy(() => import('./components/Subscriptions/EmailPage'));
const NewsletterPage = lazy(() => import('./components/Subscriptions/NewsletterPage'));
const ThankYouPage = lazy(() => import('./components/Subscriptions/ThankYouPage'));
const EmailPreferencesPage = lazy(() => import('./components/Subscriptions/EmailPreferencesPage'));  
const OptInUpdatePage = lazy(() => import('./components/Subscriptions/OptInUpdatePage'));

const CartAddPage = lazy(() => import('./components/Commerce/CartAddPage'));
const CartPage = lazy(() => import('./components/Commerce/CartPage'));  
const RecommendedPage = lazy(() => import('./components/Commerce/RecommendedPage'));
const CheckoutPage = lazy(() => import('./components/Commerce/CheckoutPage')); 
const VerifyPage = lazy(() => import('./components/Commerce/VerifyPage'));
const CompletePage = lazy(() => import('./components/Commerce/CompletePage'));

const PayPage2 = lazy(() => import('./components/Pay/PayPage2')); 

const OnsiteHomePage = lazy(() => import('./components/Onsite/OnsiteHomePage')); 
const OnsiteCoursePage = lazy(() => import('./components/Onsite/OnsiteCoursePage')); 
const OnsiteCourseTopicPage = lazy(() => import('./components/Onsite/OnsiteCourseTopicPage'));
const OnsiteIndustryPage = lazy(() => import('./components/Onsite/OnsiteIndustryPage')); 
const OnsiteIndustryItemPage = lazy(() => import('./components/Onsite/OnsiteIndustryItemPage'));  
const OnsitePillarPage = lazy(() => import('./components/Onsite/OnsitePillarPage')); 
const OnsiteModalityPage = lazy(() => import('./components/Onsite/OnsiteModalityPage')); 
const OnsiteModalityItemPage = lazy(() => import('./components/Onsite/OnsiteModalityItemPage'));
const OnsiteRepPage = lazy(() => import('./components/Onsite/OnsiteRepPage'));  
const OnsiteContactPage = lazy(() => import('./components/Onsite/OnsiteContactPage'));
const OnsiteReviewsPage = lazy(() => import('./components/Onsite/OnsiteReviewsPage'));

const CategoryPage = lazy(() => import('./components/Category/CategoryPage'));
const CategoryItemPage = lazy(() => import('./components/Category/CategoryItemPage'));

const ContentPage = lazy(() => import('./components/Content/ContentPage'));

const CertificatePage = lazy(() => import('./components/Certificate/CertificatePage')); 

export default () => {

  Search.useFindResultsWatch();

  useScrollToTopEffect();
  const location = useLocation();

  React.useEffect(() => {
    // runs on location, i.e. route, change
    window.jstag.pageView();
    window.jstag.loadEntity(); // refreshes the user profile and all configured integrations
  }, [location])

  const { brand, country, launchQueryParams }  = useSite();
  const search = Search.useSearch();
  const cart = Cart.useCart();
  const [gtmVariables, setGTMVariables] = useGtmVariables();
  const {isCustomerService} = useCustomerService();

  const [bodyClasses] = useState(() => classNames(brand.code, country.abbr));
  const [titleTemplate] = useState(() => `%s | ${brand.name}`);

  const dispatch = useDispatch();

  useEffectOnce(() => {

    const setSearch = (criteria, categoryId, autoExpand) => dispatch(Search.actionCreators.set(criteria, categoryId, autoExpand));

    const load = async () => {

      if (location.pathname !== '/cart/add') {

        if (launchQueryParams.clientKey) {

          dispatch(Cart.actionCreators.load(launchQueryParams.clientKey));
        } else if (cart.clientKey) {

          dispatch(Cart.actionCreators.load(cart.clientKey));
        } else {

          dispatch(Cart.actionCreators.loadCleanCart());
        }
      }

      const autoExpand = location.pathname.match(/\/land/i);
      if (launchQueryParams.search) {

        setSearch(launchQueryParams.search, launchQueryParams.categoryId, true);
      } else if (!search.lat) {
        setSearch({ postalCode: '66202', lat: 39.0247, lng: -94.6709 }, launchQueryParams.categoryId)
      } else {

        const { launching, ...ns } = search;
        setSearch(ns, launchQueryParams.categoryId, autoExpand);
      }

      if (launchQueryParams.gtmVariables) {

        setGTMVariables(launchQueryParams.gtmVariables);
      } else if (gtmVariables && Object.keys(gtmVariables).length > 0) {

        setGTMVariables(gtmVariables);
      }

      if (window.innerWidth > 768 && !isIE) {

        AOS.init({
          duration: 2000
        })
      }
    };

    load();
  });

  useEffect(() => {
    if (search.lat && search.lng && search.radius) {
      dispatch(actionCreators.setHasSeminars(search));
    }
  }, [search.lat, search.lng, search.radius]);

  const showHeaderFooter = !location.pathname.match(/\/land/i);

  return (
    <>
      <Helmet titleTemplate={titleTemplate}>
        <title>Welcome</title>
        <body className={bodyClasses} />
      </Helmet>

      {showHeaderFooter && <Header />}
      <div className={showHeaderFooter ? 'content-wrap' : ''} id="mainContent">
        <Suspense fallback={<LoadingSpinner />}>
          <Switch>

            <Route exact path="/">
              <HomePage contentType="home" />
            </Route>
            <Route path="/certificate">
              <CertificatePage/>
            </Route>
            <Route path="/search">
              <SearchPage contentType="search" />
            </Route>

            <Route path="/seminar/:contentRoute">
              <SeminarTopicPage />
            </Route>
            <Route path="/seminar">
              <SeminarPage />
            </Route>

            <Route path="/virtual/:contentRoute">
              <VirtualSeminarTopicPage />
            </Route>
            <Route path="/virtual">
              <VirtualSeminarPage />
            </Route>

            <Route path="/land/webinar/launch">
              <WebinarLaunchPage />
            </Route>
            <Route path="/webinar/:contentRoute">
              <WebinarTopicPage />
            </Route>
            <Route path="/webinar">
              <WebinarPage />
            </Route>

            <Route path="/product/:contentRoute">
              <ProductItemPage />
            </Route>
            <Route path="/product">
              <ProductPage />
            </Route>

            <Route path="/land/OSHA">
              <OSHALandingPage />
            </Route>
            <Route path="/land/conflict">
              <ConflictLandingPage />
            </Route>
            <Route path="/land/business-writing">
              <BusWritingLandingPage />
            </Route>
            <Route path="/land/introverted-leader">
              <XMNINTLandingPage />
            </Route>
            <Route path="/land/skillpath-unlimited-dominos">
              <Dominos />
            </Route>
            
            <Redirect
              from="/tx2024"
              to="/land/tx2024?se=TX2024&utm_source=directmail&utm_medium=brochure&utm_campaign=202403&utm_topic=inperson&utm_stareq=TX2024"
            />
            <Redirect
              from="/ga2024"
              to="/land/ga2024?se=GA2024&utm_source=directmail&utm_medium=brochure&utm_campaign=202403&utm_topic=inperson&utm_stareq=GA2024"
            />
            <Redirect
              from="/md2024"
              to="/land/md2024?se=MD2024&utm_source=directmail&utm_medium=brochure&utm_campaign=202403&utm_topic=inperson&utm_stareq=MD2024"
            />
            
            {isCustomerService && 
              <Route path="/cs-links">
                <CustomerServiceLinks />
              </Route>
            }
            <Redirect
              from="/star12-es-passes"
              to="/cs-links"
            />

            <Route path="/land/assertiveness-quiz">
              <AssertivenessQuiz />
            </Route>
            <Route path="/land/hiring-quiz">
              <HiringQuiz />
            </Route>

            <Route path="/star12/renewal">
              <PassRenewalPage />
            </Route>
            <Route path="/star12/conversion">
              <PassConversionPage />
            </Route>
            <Route path="/unlimited/renewal">
              <UnlimitedRenewalPage />
            </Route>
            <Route path="/unlimited/ondemand-pass" >
              <PassItemPage contentRoute="ondemand-pass" />
            </Route>
            <Route path="/unlimited/live-pass">
              <PassItemPage contentRoute="live-pass" />
            </Route>

            <Route path="/blog/:contentRoute">
              <BlogPostPage />
            </Route>
            <Route path="/blog">
              <BlogPage />
            </Route>

            <Route path="/news/:contentRoute">
              <NewsItemPage />
            </Route>
            <Route path="/newsroom">
              <NewsPage />
            </Route>

            <Route path="/ondemand/courses">
              <OndemandPage />
            </Route>
            <Route path="/ondemand/:contentRoute">
              <OndemandCoursePage />
            </Route>

            {/* do not use children here, the window.location fires */}
            <Route path="/enterprise" render={() => (brand === BRANDS.NST ? <Redirect to="/corporate-training" /> : <div className="pl-5">Redirecting to {window.location = ENTERPRISE_URL}</div>)} />


            <Route path="/resource/:contentRoute">
              <ResourceItemPage />
            </Route>
            <Route path="/resource">
              <ResourcePage />
            </Route>
            <Route path="/resources">
              <ResourceLandingPage />
            </Route>

            <Route path="/podcast/:podcast/:contentRoute">
              <PodcastEpisodePage />
            </Route>
            <Route path="/podcast/:contentRoute">
              <PodcastPage />
            </Route>
            <Route path="/podcast">
              <PodcastLandingPage />
            </Route>

            <Route path="/cart/add">
              <CartAddPage />
            </Route>
            <Route path="/cart">
              <CartPage />
            </Route>
            <Route path="/checkout/recommended">
              <RecommendedPage />
            </Route>
            <Route path="/checkout/verify">
              <VerifyPage />
            </Route>
            <Route path="/checkout/complete">
              <CompletePage />
            </Route>
            <Route path="/checkout">
              <CheckoutPage />
            </Route>

            <Route path="/pay" >
              <PayPage2 />
            </Route>

            <Route path="/email-signup">
              <EmailPage />
            </Route>
            <Route path="/newsletter-signup">
              <NewsletterPage />
            </Route>
            <Route path="/thank-you">
              <ThankYouPage />
            </Route>
            <Route path="/email-preferences">
              <EmailPreferencesPage />
            </Route>
            <Route path="/opt-in-update">
              <OptInUpdatePage />
            </Route>

            <Route path="/walgreens">
              <StaticWithSearch />
            </Route>
            <Route path="/pampered-chef">
              <StaticWithSearch />
            </Route>

            <Route path="/onsite/course/:contentRoute">
              <OnsiteCourseTopicPage />
            </Route>
            <Route path="/onsite/course">
              <OnsiteCoursePage />
            </Route>

            <Route path="/onsite/industry/:contentRoute">
              <OnsiteIndustryItemPage />
            </Route>
            <Route path="/onsite/industry">
              <OnsiteIndustryPage />
            </Route>

            <Route path="/onsite/pillar/:contentRoute">
              <OnsitePillarPage />
            </Route>

            <Route path="/onsite/modality/:contentRoute">
              <OnsiteModalityItemPage />
            </Route>
            <Route path="/onsite/modality">
              <OnsiteModalityPage />
            </Route>

            <Route path="/onsite/rep/:contentRoute">
              <OnsiteRepPage />
            </Route>

            <Route path="/onsite/contact">
              <OnsiteContactPage />
            </Route>

            <Route path="/onsite/reviews">
              <OnsiteReviewsPage />
            </Route>

            <Route exact path="/onsite">
              <OnsiteHomePage />
            </Route>

            <Route path="/category/:contentRoute">
              <CategoryItemPage />
            </Route>
            <Route path="/category">
              <CategoryPage />
            </Route>

            <Route>
              <ContentPage />
            </Route>
          </Switch>
        </Suspense>
      </div>
      {showHeaderFooter && <Footer />}

      <ModalRoot />
      <GDPRPanel />      
      <BackToTopButton />
      <NeedsModal />
    </>
  );
}
